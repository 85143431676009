<template>
  <span class="ml-10">
    <el-button type="text"
               @click="showTable">派发详情</el-button>
    <el-dialog :title="`${params.coupon_stock_id}派发详情`"
               :close-on-click-modal='false'
               :visible.sync="dialogVisible"
               width="70%">
      <table-page dataName="issues"
                  ref="table"
                  init
                  :search="search"
                  @on-success="onSuccess"
                  :params="paramsObj"
                  :request="fetchIssues">
        <template slot="button">

          <el-button icon="el-icon-download"
                     v-if="exportUrl"
                     @click="exportFile">导出</el-button>
        </template>
        <el-table-column prop="card_name"
                         show-overflow-tooltip
                         label="关联券">
        </el-table-column>
        <el-table-column prop="wx_coupon_id"
                         show-overflow-tooltip
                         label="代金券id">
        </el-table-column>
        <el-table-column prop="name"
                         show-overflow-tooltip
                         label="派发用户">
        </el-table-column>
        <el-table-column prop="account"
                         show-overflow-tooltip
                         label="领取状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status">
              <el-badge is-dot
                        type="success"
                        class="mr-15"></el-badge>已领取
            </span>
            <span v-else>
              <el-badge is-dot
                        type="danger"
                        class="mr-15"></el-badge>未领取
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="received_at"
                         show-overflow-tooltip
                         label="领取时间">
        </el-table-column>
        <el-table-column prop="marketer_name"
                         show-overflow-tooltip
                         label="派发员工">
        </el-table-column>
        <el-table-column prop="created_at"
                         show-overflow-tooltip
                         label="派发时间">
        </el-table-column>

        <el-table-column prop="created_at"
                         show-overflow-tooltip
                         label="核销状态">
          <template slot-scope="scope">
            <span v-if="scope.row.verify_status === 'SENDED'">
              <el-badge is-dot
                        type="warning"
                        class="mr-15"></el-badge>未使用
            </span>
            <span v-else-if="scope.row.verify_status === 'EXPIRED'">
              <el-badge is-dot
                        type="warning"
                        class="mr-15"></el-badge>未使用
            </span>
            <span v-else-if="scope.row.verify_status === 'USED'">
              <el-badge is-dot
                        type="success"
                        class="mr-15"></el-badge>已使用
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="used_at"
                         show-overflow-tooltip
                         label="核销时间">
        </el-table-column>
      </table-page>
      <span slot="footer"
            class="dialog-footer">

      </span>
    </el-dialog>
  </span>
</template>

<script>
import TablePage from '@/components/TablePage'
import { fetchIssues } from '@/api/market'
export default {
  props: ['params'],
  data () {
    return {
      stock_id: '',
      fetchIssues,
      dialogVisible: false,
      paramsObj: {},
      inputList: [{
        placeholder: '请输入关键词',
        key: 'keyword',
        value: ''
      }],
      search: [{
        type: 'input',
        placeholder: '请输入关键词',
        key: 'keyword',
        value: ''
      }],
      exportUrl: ''
    }
  },
  components: {
    TablePage
  },
  methods: {
    onSuccess (e) {
      this.exportUrl = e.export_url
    },
    exportFile () {
      window.open(this.exportUrl, '_blank')
    },
    showTable () {
      this.paramsObj = {
        allot_id: this.params.id
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.table.fetch()
        this.$refs.search.reset()
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
