<template>
  <div class="container">
    <el-button type="text"
               @click="showTable">库存历史</el-button>
    <el-dialog title="库存历史"
               :close-on-click-modal='false'
               :visible.sync="dialogVisible"
               width="50%">
      <table-page dataName="histories"
                  ref="table"
                  init
                  :params="paramsObj"
                  :request="fetchRepertories">
        <el-table-column prop="name"
                         show-overflow-tooltip
                         label="操作名称">
        </el-table-column>
        <el-table-column prop="created_at"
                         show-overflow-tooltip
                         label="操作时间">
        </el-table-column>
        <el-table-column prop="account"
                         show-overflow-tooltip
                         label="操作账号">
        </el-table-column>
        <el-table-column prop="num"
                         show-overflow-tooltip
                         label="增减数量">
        </el-table-column>
      </table-page>
      <span slot="footer"
            class="dialog-footer">

      </span>
    </el-dialog>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import { fetchRepertories } from '@/api/market'
export default {
  props: ['params'],
  data () {
    return {
      fetchRepertories,
      dialogVisible: false,
      paramsObj: {}
    }
  },
  components: {
    TablePage
  },
  methods: {
    showTable () {
      this.paramsObj = {
        fromable_id: this.params.id,
        fromable_type: this.params.type
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
