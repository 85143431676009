<template>
  <span class="ml-10">
    <el-button type="text"
               @click="changeQuan">修改库存</el-button>
    <el-dialog title="修改库存"
               :close-on-click-modal='false'
               width="30%"
               :visible.sync="dialogFormVisible">
      <el-form :model="form"
               label-width="80px"
               ref="form"
               :rules="rules"
               label-position="left">
        <el-form-item label="库存数量"
                      prop="quantity">
          <el-input v-model="form.quantity"
                    placeholder="请输入库存数量"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import { setAllotQuantity } from '@/api/market'
export default {
  props: ['params'],
  data () {
    return {
      dialogFormVisible: false,
      form: {
        allot_id: '',
        quantity: ''
      },
      rules: {
        quantity: [
          { required: true, message: '请输入修改库存数量', trigger: 'change' }
        ]

      }
    }
  },
  components: {
  },
  methods: {
    submitForm (formName) {
      const { form } = this
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setAllotQuantity(form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.dialogFormVisible = false
            this.$emit('success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    },
    changeQuan () {
      this.dialogFormVisible = true
      this.form = {
        allot_id: this.params.allot_id,
        quantity: this.params.quantity
      }
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
