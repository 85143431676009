<template>
  <el-dialog title="编辑"
             :close-on-click-modal='false'
             width="50%"
             :visible.sync="dialogVisible">
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-form-item label="活动时间"
                    prop="started_at">
        <el-date-picker v-model="dateTime"
                        @change="dateChange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-switch v-model="form.is_open"></el-switch>
      </el-form-item>
      <el-form-item label="是否直接派发">
        <el-switch v-model="form.is_issue"></el-switch>
      </el-form-item>
      <el-form-item label="是否加入员工主页（此设置将公开派发，请谨慎操作）"
                    v-permission="['ADMIN']">
        <el-switch v-model="form.is_marketer_web"></el-switch>
      </el-form-item>
      <el-form-item label="是否加入网点主页（此设置将公开派发，请谨慎操作）"
                    v-permission="['ADMIN']">
        <el-switch v-model="form.is_net_web"></el-switch>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button type="primary"
                 @click="submitForm('form')">保存修改</el-button>
    </span>
  </el-dialog>
</template>

<script>

import { editAllot } from '@/api/market'
export default {
  data () {
    return {
      dateTime: [],
      form: {},
      rules: {
        tel: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        started_at: [
          { required: true, message: '请选择活动日期', trigger: 'change' }
        ]
      },
      dialogVisible: false,
      options: [],
      selectLoading: false
    }
  },
  components: {
  },
  methods: {
    dateChange (val) {
      this.form.started_at = val[0]
      this.form.ended_at = val[1]
    },
    showModel (formData) {
      this.dialogVisible = true
      if (formData) {
        this.dateTime = [formData.started_at, formData.ended_at]
        this.form = Object.assign({}, formData)
        this.form.allot_id = formData.id
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await editAllot(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
