<template>
  <div class="container">
    <table-page dataName="allots"
                :search="search"
                ref="table"
                :extraSearchParam="extraSearchParam"
                @on-success="fetchSuccess"
                :request="fetchAllots">
      <template slot="button">
        <el-button type="primary"
                   @click="$router.push('setAllot')">新建分配</el-button>
        <el-button icon="el-icon-download"
                   class="mr-10"
                   v-if="exportAllotUrl"
                   @click="exportFile('exportAllotUrl')">导出分配记录</el-button>
        <el-button icon="el-icon-download"
                   class="mr-10"
                   v-if="exportIssueUrl"
                   @click="exportFile('exportIssueUrl')">导出派发详情汇总</el-button>
        <el-button icon="el-icon-download"
                   class="mr-10"
                   v-if="exportNetIssueUrl"
                   @click="exportFile('exportNetIssueUrl')">导出网点主页领取记录</el-button>
        <el-button icon="el-icon-download"
                   class="mr-10"
                   v-if="exportRefuelUrl"
                   @click="exportFile('exportRefuelUrl')">导出加油券领取记录</el-button>
      </template>
      <el-table-column prop="coupon_stock_id"
                       show-overflow-tooltip
                       label="区域">
        <template slot-scope="scope">
          {{scope.row.province}}{{scope.row.city}}{{scope.row.district}}{{scope.row.net}}
        </template>
      </el-table-column>
      <el-table-column prop="coupon_stock_id"
                       show-overflow-tooltip
                       label="批次号">
        <template slot-scope="scope">
          <span>{{scope.row.type == 'VOUCHER' ? '品牌卡券':scope.row.coupon_stock_id}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="card_name"
                       show-overflow-tooltip
                       label="关联券">
      </el-table-column>
      <el-table-column prop="total_inventory"
                       show-overflow-tooltip
                       label="数量">
      </el-table-column>
      <el-table-column prop="quantity"
                       show-overflow-tooltip
                       label="剩余">
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="起止时间">
        <template slot-scope="scope">
          {{scope.row.started_at}}-{{scope.row.ended_at}}
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       width="200">
        <template slot-scope="scope">
          <el-button type="text"
                     v-permission="['ADMIN']"
                     @click="$refs.editAllot.showModel(scope.row)">编辑</el-button>
          <Issues :params="scope.row" />
          <SetQuantity @success="$refs.table.fetch()"
                       :params="{allot_id:scope.row.id,quantity:scope.row.quantity}" />
        </template>
      </el-table-column>
    </table-page>
    <EditAllot ref="editAllot"
               @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import SetQuantity from './SetQuantity'
import EditAllot from './EditAllot'
import Issues from './Issues'
import { fetchAllots } from '@/api/market'
export default {
  data () {
    return {
      fetchAllots,
      params: {},
      search: [{ type: 'area' }, {
        type: 'input',
        placeholder: '请输入批次号',
        name: '批次号',
        key: 'coupon_stock_id',
        value: ''
      }],
      exportAllotUrl: '',
      exportIssueUrl: '',
      exportRefuelUrl: '',
      exportNetIssueUrl: ''
    }
  },
  components: {
    TablePage,
    SetQuantity,
    Issues,
    EditAllot
  },
  methods: {
    fetchSuccess (e) {
      this.exportUrl = e.export_url
      this.exportAllotUrl = e.export_url
      this.exportIssueUrl = e.export_issue_url
      this.exportRefuelUrl = e.export_refuel_url
      this.exportNetIssueUrl = e.export_voucher_with_area
    },
    exportFile (name) {
      if (this[name] === '') {
        this.$Message.error('暂时无法导出')
      } else {
        window.open(this[name], '_blank')
      }
    }

  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
