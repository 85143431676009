<template>
  <div class="container">
    <el-button type="primary"
               class="mt-15"
               @click="$router.push('setAllot')">新建分配</el-button>
    <table-page dataName="allots"
                ref="table"
                :request="fetchAllotCards">
      <el-table-column prop="coupon_stock_id"
                       show-overflow-tooltip
                       label="批次号">
      </el-table-column>
      <el-table-column prop="card_name"
                       show-overflow-tooltip
                       label="券名称">
      </el-table-column>
      <el-table-column prop="quantity"
                       show-overflow-tooltip
                       label="可分配库存">
      </el-table-column>
      <el-table-column prop="started_at"
                       show-overflow-tooltip
                       label="开始时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <Repertories :params="scope.row"
                       ref="repertories" />

        </template>
      </el-table-column>
    </table-page>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import Repertories from './Repertories'
import { fetchAllotCards } from '@/api/market'
export default {
  data () {
    return {
      fetchAllotCards,
      params: {}
    }
  },
  components: {
    TablePage,
    Repertories
  },
  methods: {
    showRepertories (row) {
      this.params = {
        fromable_id: row.id,
        fromable_type: row.type
      }
      this.$refs.repertories.showModel()
    }

  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
