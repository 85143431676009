<template>
  <div class="container">
    <el-tabs v-model="activeName">
      <el-tab-pane label="可分配"
                   name="first">
        <CanAllot />
      </el-tab-pane>
      <el-tab-pane label="已分配"
                   name="second">
        <HasAllot />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CanAllot from './components/CanAllot'
import HasAllot from './components/HasAllot'
export default {
  name: 'Allot',
  data () {
    return {
      activeName: 'first'
    }
  },
  components: {
    CanAllot,
    HasAllot
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
